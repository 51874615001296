<template>
  <div id="index">
    <!-- 手机展示溢满等四种情况 -->
    <!-- <div class="phoneshow" v-show="phoneShow">
            <div class="index_sheet_small_phone" style="color: rgb(224, 234, 101);">
                <div style="text-align: center;font-weight: 700;">
                    <p>
                        正常
                    </p>
                    <p>
                        {{ tong.running }}个
                    </p>
                </div>
            </div>
            <div class="index_sheet_small_phone" style="color: rgb(32, 247, 214);">
                <div style="text-align: center;font-weight: 700;">
                    <p>
                        离线
                    </p>
                    <p>
                        {{ tong.dis_connect }}个
                    </p>
                </div>

            </div>
            <div class="index_sheet_small_phone" style="color: rgb(219, 103, 106);">
                <div style="text-align: center;font-weight: 700;">
                    <p>
                        故障
                    </p>
                    <p>
                        {{ tong.breakdown }}个
                    </p>
                </div>

            </div>
            <div class="index_sheet_small_phone" style="color:red;">
                <div style="text-align: center;font-weight: 700;">
                    <p>
                        满溢
                    </p>
                    <p>
                        {{ num }}个
                    </p>
                </div>
            </div>
        </div> -->

    <div id="index_container"><Map-container></Map-container></div>
    <div id="index_top" v-if="showtop">
      <div class="index_top_title title" @click="tong0">智慧环卫平台</div>
      <div class="index_top_time">
        <span class="title"
          >{{ Year }}年{{ Month }}月{{ Day }}日 {{ Days }} {{ Hours }}:{{
            Minutes
          }}:{{ Second }}</span
        >
        <span class="exit_login" @click="exitLogin">退出登录</span>
        <span class="index_top_time_name title"
          >管理员:{{ $store.state.username }}</span
        >
      </div>
    </div>
    <div id="index_top1" v-if="!showtop">
      <div class="index_top_title title" @click="tong0">智慧环卫平台</div>
      <div class="index_top_time">
        <span class="title"
          >{{ Year }}年{{ Month }}月{{ Day }}日 {{ Days }} {{ Hours }}:{{
            Minutes
          }}:{{ Second }}</span
        >
        <span class="index_top_time_name title"
          >管理员:{{ $store.state.username }}</span
        >
      </div>
    </div>
    <div
      class="left_switch left_switch_one"
      v-if="!leftBox[0]"
      @click="openLeftBox(0)"
    >
      <i class="el-icon-caret-right"></i>
    </div>
    <div
      class="left_switch left_switch_two"
      v-if="!leftBox[1]"
      @click="openLeftBox(1)"
    >
      <i class="el-icon-caret-right"></i>
    </div>
    <div
      class="left_switch left_switch_three"
      v-if="!leftBox[2]"
      @click="openLeftBox(2)"
    >
      <i class="el-icon-caret-right"></i>
    </div>
    <div
      class="left_switch left_switch_four"
      v-if="!leftBox[3]"
      @click="openLeftBox(3)"
    >
      <i class="el-icon-caret-right"></i>
    </div>

    <div
      class="right_switch right_switch_one"
      v-if="!rightBox[0]"
      @click="openRightBox(0)"
    >
      <i class="el-icon-caret-left"></i>
    </div>
    <div
      class="right_switch right_switch_two"
      v-if="!rightBox[1]"
      @click="openRightBox(1)"
    >
      <i class="el-icon-caret-left"></i>
    </div>
    <div
      class="right_switch right_switch_three"
      v-if="!rightBox[2]"
      @click="openRightBox(2)"
    >
      <i class="el-icon-caret-left"></i>
    </div>
    <div
      class="right_switch right_switch_four"
      v-if="!rightBox[3]"
      @click="openRightBox(3)"
    >
      <i class="el-icon-caret-left"></i>
    </div>

    <!-- <div class="index_sheet_left_small_one" v-show="leftBox[0] && !phoneShow">
            <i style="position: absolute;right: 7px;top: 12px;" class="el-icon-close" @click="closeLeftBox(0)"></i>
            <p :class="width1 > 7000 ? 'index_sheet_small_top_title1 title' : 'index_sheet_small_top_title_small title'">
            </p>
            <div class="index_sheet_small_top" @click="to('/test2')">
                <div class="small_top small_top_yellow">
                    <img class="small_top_img" src="../assets/index-yellow.png" alt="">
                    <div :class="width1 > 7000 ? 'small_top_title1' : 'small_top_title'">
                        <div>
                            <p :class="width1 > 7000 ? 'small_top_title_big1 title' : 'small_top_title_big title'"
                                style="color:#e0ea65">{{ tong.running }}个</p>
                            <p :class="width1 > 7000 ? 'small_top_title_small1 title' : 'small_top_title_small title'"
                                style="color:#e0ea65">正在进行</p>
                        </div>
                    </div>
                </div>
                <div class="small_top small_top_green">
                    <img class="small_top_img" src="../assets/index-green.png" alt="">
                    <div :class="width1 > 7000 ? 'small_top_title1' : 'small_top_title'">
                        <div>
                            <p :class="width1 > 7000 ? 'small_top_title_big1 title' : 'small_top_title_big title'"
                                style="color:#20f7d6">{{ tong.dis_connect }}个</p>
                            <p :class="width1 > 7000 ? 'small_top_title_small1 title' : 'small_top_title_small title'"
                                style="color:#20f7d6">离线状态</p>
                        </div>
                    </div>
                </div>
                <div class="small_top small_top_red">
                    <img class="small_top_img" src="../assets/index-red.png" alt="">
                    <div :class="width1 > 7000 ? 'small_top_title1' : 'small_top_title'">
                        <div>
                            <p :class="width1 > 7000 ? 'small_top_title_big1 title' : 'small_top_title_big title'"
                                style="color:#db676a">{{ tong.breakdown }}个</p>
                            <p :class="width1 > 7000 ? 'small_top_title_small1 title' : 'small_top_title_small title'"
                                style="color:#db676a">故障状态</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    <div
      class="index_sheet_big index_sheet_big_left_two"
      v-show="leftBox[1] && !phoneShow"
    >
      <i
        style="position: absolute; right: 9px; top: 15px"
        class="el-icon-close"
        @click="closeLeftBox(1)"
      ></i>
      <p
        :class="
          width1 > 7000
            ? 'index_sheet_small_top_title1 title'
            : 'index_sheet_small_top_title title'
        "
      ></p>
      <!-- <p :class="width1 > 7000 ? 'index_sheet_small_top_title1 title' : 'index_sheet_small_top_title_big title'"
                style="margin-bottom: 5px;">桶位配置
            </p> -->
      <div id="garbage_volume_sheet" @click="to('/test2')">
        <p :class="width1 > 7000 ? 'rollp1' : 'rollp'">
          <span style="color: white; font-weight: 700">桶位名称</span>
          <span style="color: white; font-weight: 700">有害垃圾</span>
          <span style="color: white; font-weight: 700">可回收物</span>
          <span style="color: white; font-weight: 700">其他垃圾</span>
          <span style="color: white; font-weight: 700">厨余垃圾</span>
        </p>
        <div style="overflow: hidden">
          <vue-seamless-scroll
            :data="rolldata"
            class="seamless-warp"
            :class-option="classOption"
          >
            <p
              :class="width1 > 7000 ? 'rollp1' : 'rollp'"
              v-for="item in rolldata"
              :key="item.point_id"
            >
              <span style="color: white">{{ item.point_name }}</span>
              <span
                v-for="item1 in item.barrel_info"
                :key="item1.barrel_type"
                :style="{ color: colorarr[item1.barrel_type] }"
                >{{ item1.is_set }}个</span
              >
            </p>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
    <div
      class="index_sheet_small index_sheet_big_left_three"
      v-show="leftBox[2] && !phoneShow"
    >
      <i
        style="position: absolute; right: 7px; top: 12px"
        class="el-icon-close"
        @click="closeLeftBox(2)"
      ></i>
      <p
        :class="
          width1 > 7000
            ? 'index_sheet_small_top_title1 title'
            : 'index_sheet_small_top_title_small title'
        "
      ></p>
      <div id="buried_barrel_status">
        <!-- <div class="buried_barrel_status99">
          <div id="buried_barrel_status1" class="buried_barrel_status100"></div>
          <div class="buried_barrel_status100_text">
            <p :style="width1 > 7000 ? 'font-size: 24px' : 'font-size: 12px'">
              0件
            </p>
            <p :style="width1 > 7000 ? 'font-size: 30px' : 'font-size: 15px'">
              乱扔情况
            </p>
          </div>
        </div>
        <div id="" class="buried_barrel_status99">
          <div id="buried_barrel_status2" class="buried_barrel_status100"></div>
          <div class="buried_barrel_status100_text">
            <p :style="width1 > 7000 ? 'font-size: 24px' : 'font-size: 12px'">
              {{ EventNum }}件
            </p>
            <p :style="width1 > 7000 ? 'font-size: 30px' : 'font-size: 15px'">
              情况上报
            </p>
          </div>
        </div> -->
        <div id="" class="buried_barrel_status99">
          <div id="buried_barrel_status3" class="buried_barrel_status100"></div>
          <div class="buried_barrel_status100_text">
            <p :style="width1 > 7000 ? 'font-size: 24px' : 'font-size: 12px'">
              {{ barrel_empty }}个
            </p>
            <p :style="width1 > 7000 ? 'font-size: 30px' : 'font-size: 15px'">
              空桶
            </p>
          </div>
        </div>
        <div id="" class="buried_barrel_status99">
          <div id="buried_barrel_status4" class="buried_barrel_status100"></div>
          <div class="buried_barrel_status100_text">
            <p :style="width1 > 7000 ? 'font-size: 24px' : 'font-size: 12px'">
              {{ barrel_no_full }}个
            </p>
            <p :style="width1 > 7000 ? 'font-size: 30px' : 'font-size: 15px'">
              有垃圾
            </p>
          </div>
        </div>
        <div id="" class="buried_barrel_status99">
          <div id="buried_barrel_status5" class="buried_barrel_status100"></div>
          <div class="buried_barrel_status100_text">
            <p :style="width1 > 7000 ? 'font-size: 24px' : 'font-size: 12px'">
              {{ barrel_full }}个
            </p>
            <p :style="width1 > 7000 ? 'font-size: 30px' : 'font-size: 15px'">
              满溢
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 左下角实时监控 -->
    <!-- <div
      class="index_sheet_big index_sheet_big_left_four"
      v-show="leftBox[3] && !phoneShow"
    >
      <i
        style="position: absolute; right: 7px; top: 12px"
        class="el-icon-close"
        @click="closeLeftBox(3)"
      ></i>
      <p
        :class="
          width1 > 7000
            ? 'index_sheet_small_top_title1 title'
            : 'index_sheet_small_top_title title'
        "
      ></p>
      <span style="position: absolute; left: 6.44%; top: 4.56%">
        <el-dropdown @command="handleCommandLeft" trigger="click">
          <span
            :class="width1 > 7000 ? 'el-dropdown-link1' : 'el-dropdown-link'"
          >
            1号桶<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">1号桶</el-dropdown-item>
            <el-dropdown-item command="b">高速旁</el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>
      </span>

      <div
        class="videodiv"
        style="width: 100%; height: 150px; overflow: hidden"
      ></div>
    </div> -->

    <!-- <div class="index_sheet_small index_sheet_small_right_one" v-show="rightBox[0] && !phoneShow">
            <i style="position: absolute;right: 7px;top: 12px;" class="el-icon-close" @click="closeRightBox(0)"></i>
            <p
                :class="width1 > 7000 ? 'index_sheet_small_top_title1 title' : 'index_sheet_small_top_title_small title'">
            </p>
            <div class="index_sheet_right_top" @click="to('/api/vihicle/getvihiclelist')">
                <div class="index_sheet_right_car">
                    <div id="index_sheet_right_car_left">
                    </div>

                </div>
                <div class="index_sheet_right_car">
                    <div id="index_sheet_right_car_right">
                    </div>

                </div>

            </div>

        </div> -->

    <!-- <div class="index_sheet_small title index_sheet_small_right_two" v-show="rightBox[1] && !phoneShow">
            <i style="position: absolute;right: 9px;top: 15px;" class="el-icon-close" @click="closeRightBox(1)"></i>
            <p :class="width1 > 7000 ? 'index_sheet_small_top_title1 title' : 'index_sheet_small_top_title_small title'"
                style="margin-bottom: 5px;">
            </p>
            <div id="personnel" :class="width1 > 7000 ? 'personnel1' : 'personnel'" @click="to('api/staff/stafflist')">

            </div>
        </div> -->
    <!-- <div class="index_sheet_big title index_sheet_small_right_three" v-show="rightBox[2] && !phoneShow">
            <i style="position: absolute;right: 9px;top: 15px;" class="el-icon-close" @click="closeRightBox(2)"></i>
            <p :class="width1 > 7000 ? 'index_sheet_small_top_title1 title' : 'index_sheet_small_top_title title'">
            </p>
            <div id="garbage_volume_sheet" @click="to('/garbagechild3')">
                <p :class="width1 > 7000 ? 'rollp1' : 'rollp'">
                    <span style="color:white;font-weight: 700;">事件类型</span>
                    <span style="color:white;font-weight: 700;">上报时间</span>
                    <span style="color:white;font-weight: 700;">处理情况</span>
                </p>
                <div style="overflow: hidden;">
                    <vue-seamless-scroll :data="rolldata" class="seamless-warp" :class-option="classOption">
                        <p :class="width1 > 7000 ? 'rollp1' : 'rollp'" v-for="item in EventList" :key="item.point_id">
                            <span style="color:white">{{ typearr[item.type] }}</span>
                            <span style="color:white">{{ item.c_time }}</span>
                            <span :style="{
            color: item.status == 0 ? 'red' : 'white'
        }">{{ item.status == 0 ? "待处理" : "已处理"
                                }}</span>
                        </p>
                    </vue-seamless-scroll>
                </div>
            </div>

        </div> -->

    <div
      class="index_sheet_big title index_sheet_small_right_four"
      v-show="rightBox[3] && !phoneShow"
    >
      <i
        style="position: absolute; right: 5px; top: 15px"
        class="el-icon-close"
        @click="closeRightBox(3)"
      ></i>
      <p
        :class="
          width1 > 7000
            ? 'index_sheet_small_top_title1 title'
            : 'index_sheet_small_top_title title'
        "
      ></p>
      <!-- <span style="position: absolute; left: 3.86%;top: 4.56%;">
                <el-dropdown @command="handleCommandRight" trigger="click">
                    <span :class="width1 > 7000 ? 'el-dropdown-link1' : 'el-dropdown-link'">
                        广场街道<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="a">XX街道</el-dropdown-item>
                        <el-dropdown-item command="b">XX街道</el-dropdown-item>
                        <el-dropdown-item command="c">XX街道</el-dropdown-item>
                        <el-dropdown-item command="d">XX街道</el-dropdown-item>
                        <el-dropdown-item command="e">XX街道</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

            </span> -->
      <div id="garbage_volume_sheet" @click="to('/test15')">
        <p :class="width1 > 7000 ? 'rollp1' : 'rollp'">
          <span style="color: white; font-weight: 700">街道地址</span>
          <span style="color: white; font-weight: 700">车辆类型</span>
          <span style="color: white; font-weight: 700">车辆车牌</span>
        </p>
        <div style="overflow: hidden">
          <vue-seamless-scroll
            :data="rolldata"
            class="seamless-warp"
            :class-option="classOption"
          >
            <p
              :class="width1 > 7000 ? 'rollp1' : 'rollp'"
              v-for="carItem in rightArr"
            >
              <span style="color: white">{{ carItem.street_name }}</span>
              <span style="color: white">{{ carItem.vihicle_type }}</span>
              <span style="color: white">{{ carItem.vihicle_number }}</span>
            </p>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MapContainer from "@/components/MapContainer/MapContainer.vue";

import {
  getLiveSource,
  GetPointList,
  getEventListWeb,
  getHomePageOther,
  GetHomePageMain,
  getVihicleList,
} from "../request/api";
import flvjs from "flv.js";
// import "echarts-liquidfill";
export default {
  name: "DemoIndex",
  components: {
    MapContainer,
  },
  data() {
    return {
      Days: "", //星期几
      Year: "", //年份
      Month: "", //月份
      Day: "", //日期
      Hours: "", //小时
      Minutes: "", //分钟
      Second: "", //秒
      //左侧信息窗盒子是否显示
      leftBox: [true, true, true, true],
      //右侧信息窗盒子是否显示
      rightBox: [true, true, true, true],
      width1: null,
      player1: null,
      player: null,
      showtop: true,
      rolldata: [],
      colorarr: ["yellow", "red", "skyblue", "#e3e1e2", "greenyellow"],
      tong: {
        running: null,
        dis_connect: null,
        breakdown: null,
      },
      EventList: [],
      typearr: ["", "乱丢乱扔", "冒烟起火", "故障维修", "其他", "环境卫生"],
      phoneShow: false,
      total_counts: 0,
      barrel_full: 0,
      barrel_empty: 0,
      barrel_no_full: 0,
      num: 0,
      street_people_counts: [],
      EventNum: 0,
      leftArr: [],
      rightArr: [],
    };
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  async created() {
    this.getVihicleListFn();
    // this.getVihicleListFn("环卫车辆", 1)
    let res = await getHomePageOther();
    this.tong = res.data.working_info;

    //离线设备个数

    let res1 = await getEventListWeb();
    this.EventList = res1.data;
    this.EventNum = this.EventList.filter((item) => {
      return item.status == 0;
    }).length;
  },

  async mounted() {
    const el = document.querySelector("#index");
    this.width1 = el.offsetWidth;
    if (this.width1 > 7000) {
      this.showtop = false;
    } else if (this.width1 < 900) {
      this.phoneShow = true;
    }
    let res2 = await GetHomePageMain();
    this.street_people_counts = res2.data.street_people_counts;
    res2.data.point_list.forEach((item) => {
      this.barrel_full += item.barrel_full_info.barrel_full;
      this.total_counts += item.barrel_full_info.total_counts;
      this.barrel_no_full += item.barrel_full_info.barrel_no_full;
      this.barrel_empty += item.barrel_full_info.barrel_empty;
      if (item.barrel_full_info.barrel_full > 0) {
        this.num++;
      }
    });
    //获取当前时间
    this.getDatetime();
    //环卫车辆饼图
    // this.sanitationvehicles();
    //电动三轮车饼图
    // this.electrictricycle();
    //人员情况柱状图
    // this.personnel()
    //地埋桶立体柱状图
    this.buriedbarrelstatus();

    let res1 = await GetPointList({
      page: "1",
      limit: "1000",
    });

    this.rolldata = res1.data.list;
    // debugger
    // let res = await getLiveSource({
    //     point_id: 88
    // })
    // let ch1 = res.data.ch1
    // let ch2 = res.data.ch2
    // if (flvjs.isSupported()) {
    //     let video = this.$refs.player;
    //     let video1 = this.$refs.player1;
    //     this.player = flvjs.createPlayer({
    //         type: "flv",
    //         isLive: true,
    //         url: ch1
    //     });
    //     this.player1 = flvjs.createPlayer({
    //         type: "flv",
    //         isLive: true,
    //         url: ch2
    //     });
    //     this.player.attachMediaElement(video);
    //     this.player1.attachMediaElement(video1);
    //     try {

    //     } catch (error) {
    //         console.log(error);
    //     };

    // }
  },

  methods: {
    //点击地埋桶离线数量变成0
    tong0() {
      this.tong.dis_connect = 0;
    },
    //获取车辆数据
    async getVihicleListFn(type, a) {
      let res = await getVihicleList({
        page: 1,
        limit: 1000,
        vihicle_type: "环卫车辆",
      });

      this.rightArr = res.data.list;
      // if (a === 0) {
      //     this.leftArr = res.data.list
      // } else {
      //     this.rightArr = res.data.list
      // }
    },
    play() {
      this.player.load();
      this.player.play();
    },
    play1() {
      this.player1.load();
      this.player1.play();
    },
    //关闭左侧信息窗盒子
    closeLeftBox(id) {
      this.$set(this.leftBox, id, false);
    },
    //关闭右侧信息窗盒子
    closeRightBox(id) {
      this.$set(this.rightBox, id, false);
    },
    //打开左侧信息窗盒子
    openLeftBox(id) {
      this.$set(this.leftBox, id, true);
    },
    //打开右侧信息窗盒子
    openRightBox(id) {
      this.$set(this.rightBox, id, true);
    },
    //获取当前时间
    getDatetime() {
      clearInterval(gettime);
      var gettime = setInterval(() => {
        const date = new Date();
        const days = date.getDay();
        const arr = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        this.Days = arr[days];
        this.Year = date.getFullYear();
        this.Month = date.getMonth() + 1;
        this.Day = date.getDate();
        this.Hours = date.getHours();
        this.Minutes =
          date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
        this.Second =
          date.getSeconds() >= 10 ? date.getSeconds() : "0" + date.getSeconds();
      }, 1000);
    },
    //三轮车饼图
    sanitationvehicles() {
      // 初始化echarts实例
      let myChart = this.$echarts.init(
        document.getElementById("index_sheet_right_car_left")
      );
      let fontsize;
      let top;
      let left;
      let num = this.leftArr.length.toString();
      let num1 = 0;
      let num2 = 0;
      let num3 = 0;
      this.leftArr.forEach((item) => {
        if (item.connect_status === 1) {
          num1 += 1;
        }
        if (item.connect_status === 0) {
          num2 += 1;
        }
        if (item.work_status === 0) {
          num3 += 1;
        }
      });
      if (this.width1 > 7000) {
        fontsize = 28;
        top = "37%";
        left = "49%";
      } else {
        fontsize = 14;
        top = "33%";
        left = "47.5%";
      }

      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
          triggerOn: "mousemove",
          backgroundColor: "rgba(1,70,86,1)",
          borderColor: "rgba(0,246,255,1)",
          borderWidth: 0.5,
          textStyle: { color: "#fff" },
        },
        // 标题
        title: [
          {
            zlevel: 0,
            text: "三轮车总数",
            subtext: num,
            top,
            left,
            textAlign: "center",
            textStyle: {
              color: "#fff",
              fontSize: fontsize,
            },
            subtextStyle: {
              fontSize: fontsize,
              fontfamily: "DIN",
              fontweight: "bold",
              color: "#19B1FB",
            },
          },
        ],
        color: ["#00D3B1", "#D9E964", "#fc4849"],

        legend: {
          top: 2,
          x: "right",
          show: true,
          orient: "horizontal",
          data: [],
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          itemGap: 20,
          itemWidth: 15, // 图例图形的宽度
          itemHeight: 10, // 图例图形的高度
        },
        series: [
          // 第一个饼图
          //数据部分圆环
          {
            name: "访问来源",
            type: "pie",
            center: ["50%", "47%"],
            radius: ["70%", "85%"],
            labelLine: {
              show: false,
              length: 30,
            },
            label: {
              show: false,
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: "#030406",
              borderWidth: 2,
            },
            data: [
              { value: num2, name: "离线" },
              { value: num1, name: "在线" },
              { value: num3, name: "故障" },
            ],
          },
          {
            animation: false,
            tooltip: {
              show: false, // 关闭鼠标移上去后显示浮框信息
              trigger: "item",
              confine: true,
            },
            name: "访问来源",
            type: "pie",
            center: ["50%", "47%"],
            radius: ["65%", "75%"],
            itemStyle: {
              normal: { color: "#0d3443" },
            },
            data: [{ value: 1048, name: "" }],
          },
          {
            name: "ring5", //最里层圆环
            type: "custom",
            coordinateSystem: "none",
            renderItem: (params, api) => {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() * 0.5,
                  cy: api.getHeight() * 0.47,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.55,
                  startAngle: (0 * Math.PI) / 180,
                  endAngle: (360 * Math.PI) / 180,
                },
                silent: true,
                style: {
                  stroke: "#1fb2d3",
                  fill: "transparent",
                  lineWidth: 0.5,
                  fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#0d3443",
                    },
                    {
                      offset: 1,
                      color: "#0d3443",
                    },
                  ]),
                },
              };
            },
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: "#4daffb" },
                { offset: 1, color: "#46d8fa" },
              ]),
            },
            data: [0],
          },
        ],
      });
    },
    //环卫车辆饼图
    electrictricycle() {
      // 初始化echarts实例
      let myChart = this.$echarts.init(
        document.getElementById("index_sheet_right_car_right")
      );
      let fontsize;
      let top;
      let left;
      let num = this.rightArr.length;
      let num1 = 0;
      let num2 = 0;
      let num3 = 0;
      this.rightArr.forEach((item) => {
        if (item.connect_status === 1) {
          num1 += 1;
        }
        if (item.connect_status === 0) {
          num2 += 1;
        }
        if (item.work_status === 0) {
          num3 += 1;
        }
      });
      if (this.width1 > 7000) {
        fontsize = 28;
        top = "37%";
        left = "49%";
      } else {
        fontsize = 14;
        top = "33%";
        left = "47.5%";
      }
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
          triggerOn: "mousemove",
          backgroundColor: "rgba(1,70,86,1)",
          borderColor: "rgba(0,246,255,1)",
          borderWidth: 0.5,
          textStyle: { color: "#fff" },
        },
        // 标题
        title: [
          {
            zlevel: 0,
            text: "环卫车总数",
            subtext: num,
            top,
            left,
            textAlign: "center",
            textStyle: {
              color: "#fff",
              fontSize: fontsize,
            },
            subtextStyle: {
              fontSize: fontsize,
              fontfamily: "DIN",
              fontweight: "bold",
              color: "#19B1FB",
            },
          },
        ],
        color: ["#00D3B1", "#D9E964", "#fc4849"],
        legend: {
          top: 2,
          x: "right",
          show: true,
          orient: "horizontal",
          data: [],
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          itemGap: 20,
          itemWidth: 15, // 图例图形的宽度
          itemHeight: 10, // 图例图形的高度
        },
        series: [
          // 第一个饼图
          //数据部分圆环
          {
            name: "访问来源",
            type: "pie",
            center: ["50%", "47%"],
            radius: ["70%", "85%"],
            labelLine: {
              show: false,
              length: 30,
            },
            label: {
              show: false,
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: "#030406",
              borderWidth: 2,
            },
            data: [
              { value: num2, name: "离线" },
              { value: num1, name: "在线" },
              { value: num3, name: "故障" },
            ],
          },
          {
            animation: false,
            tooltip: {
              show: false, // 关闭鼠标移上去后显示浮框信息
              trigger: "item",
              confine: true,
            },
            name: "访问来源",
            type: "pie",
            center: ["50%", "47%"],
            radius: ["65%", "75%"],
            itemStyle: {
              normal: { color: "#0d3443" },
            },
            data: [{ value: 1048, name: "" }],
          },
          {
            name: "ring5", //最里层圆环
            type: "custom",
            coordinateSystem: "none",
            renderItem: (params, api) => {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() * 0.5,
                  cy: api.getHeight() * 0.47,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 2) * 0.55,
                  startAngle: (0 * Math.PI) / 180,
                  endAngle: (360 * Math.PI) / 180,
                },
                silent: true,
                style: {
                  stroke: "#1fb2d3",
                  fill: "transparent",
                  lineWidth: 0.5,
                  fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#0d3443",
                    },
                    {
                      offset: 1,
                      color: "#0d3443",
                    },
                  ]),
                },
              };
            },
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: "#4daffb" },
                { offset: 1, color: "#46d8fa" },
              ]),
            },
            data: [0],
          },
        ],
      });
    },
    //人员情况柱状图
    personnel() {
      let myChart = this.$echarts.init(document.getElementById("personnel"));
      let title = this.street_people_counts.map((item) => {
        return {
          value: item.street_name,
          textStyle: {
            color: "#fff",
          },
        };
      });
      let num = this.street_people_counts.map((item) => {
        return item.user_counts;
      });
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },

        xAxis: {
          type: "category",
          data: title,
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#fff", //x轴的颜色
            },
          },
        },
        grid: {
          top: "3%",
          left: "5%",
          bottom: "4%",
          containLabel: true,
        },
        series: [
          {
            data: num,
            type: "bar",
          },
        ],
      });
    },
    //地埋桶状态立体柱状图
    buriedbarrelstatus() {
      // let myChart1 = this.$echarts.init(
      //   document.getElementById("buried_barrel_status1")
      // );
      // let myChart2 = this.$echarts.init(
      //   document.getElementById("buried_barrel_status2")
      // );
      let myChart3 = this.$echarts.init(
        document.getElementById("buried_barrel_status3")
      );
      let myChart4 = this.$echarts.init(
        document.getElementById("buried_barrel_status4")
      );
      let myChart5 = this.$echarts.init(
        document.getElementById("buried_barrel_status5")
      );
      var areaData = ["1", "1", "1", "1", "1"]; //横坐标值
      var payPersonNum = [
        0,
        50,
        (this.barrel_empty / this.total_counts) * 100,
        (this.barrel_no_full / this.total_counts) * 100,
        (this.barrel_full / this.total_counts) * 100,
      ]; //柱状图的值
      //
      const VALUE1 = [
        {
          //每个柱子的颜色
          value: payPersonNum[0],
          itemStyle: {
            color: "rgba(36,28,249,1.0)",
          },
        },
        {
          value: payPersonNum[1],
          itemStyle: {
            color: "rgba(72,74,69,1.0)",
          },
        },
        {
          value: payPersonNum[2],
          itemStyle: {
            color: "rgba(31,244,38,1.0)",
          },
        },
        {
          value: payPersonNum[3],
          itemStyle: {
            color: "rgba(226,240,67,1.0)",
          },
        },
        {
          value: payPersonNum[4],
          itemStyle: {
            color: "rgba(236,70,70,1.0)",
          },
        },
      ];
      const VALUE2 = [
        {
          // ,所看到的透明那部分
          value: 100, //这个value是柱状图的值里的最大值
          itemStyle: {
            color: "rgba(42,30,228,.3)",
          },
        },
        {
          value: 100,
          itemStyle: {
            color: "rgba(72,74,69,.3)",
          },
        },
        {
          value: 100,
          itemStyle: {
            color: "rgba(31,244,38,.3)",
          },
        },
        {
          value: 100,
          itemStyle: {
            color: "rgba(226,240,67,.3)",
          },
        },
        {
          value: 100,
          itemStyle: {
            color: "rgba(236,70,70,.3)",
          },
        },
      ];
      // 绘制左侧面
      const CubeLeft = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const xAxisPoint = shape.xAxisPoint;
          const c0 = [shape.x, shape.y];
          const c1 = [shape.x - 13, shape.y - 13];
          const c2 = [xAxisPoint[0] - 13, xAxisPoint[1] - 13];
          const c3 = [xAxisPoint[0], xAxisPoint[1]];
          ctx
            .moveTo(c0[0], c0[1])
            .lineTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .closePath();
        },
      });
      // 绘制右侧面
      const CubeRight = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const xAxisPoint = shape.xAxisPoint;
          const c1 = [shape.x, shape.y];
          const c2 = [xAxisPoint[0], xAxisPoint[1]];
          const c3 = [xAxisPoint[0] + 18, xAxisPoint[1] - 9];
          const c4 = [shape.x + 18, shape.y - 9];
          ctx
            .moveTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .lineTo(c4[0], c4[1])
            .closePath();
        },
      });
      // 绘制顶面
      const CubeTop = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const c1 = [shape.x, shape.y];
          const c2 = [shape.x + 18, shape.y - 9];
          const c3 = [shape.x + 5, shape.y - 22];
          const c4 = [shape.x - 13, shape.y - 13];
          ctx
            .moveTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .lineTo(c4[0], c4[1])
            .closePath();
        },
      });
      // 注册三个面图形
      this.$echarts.graphic.registerShape("CubeLeft", CubeLeft);
      this.$echarts.graphic.registerShape("CubeRight", CubeRight);
      this.$echarts.graphic.registerShape("CubeTop", CubeTop);
      const VALUE = payPersonNum;
      var buyTopOption;

      //五个柱子的样式设置函数
      const setbuyTopOption = (color, value, value1, value2, LEFT) => {
        buyTopOption = {
          backgroundColor: "transparent",
          tooltip: {
            trigger: "none",

            axisPointer: {
              lineStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(0, 255, 233,0)",
                    },
                    {
                      offset: 0.5,
                      color: "rgba(0, 255, 255,1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0, 255, 233,0)",
                    },
                  ],
                  global: false,
                },
              },
            },
          },
          grid: {
            //图表的上下左右的距离
            left: LEFT,
            bottom: "20%",
            top: "15%",
            padding: "0 0 0 0",
            containLabel: true,
          },
          xAxis: {
            //x轴的样式设置
            show: false,
            type: "category",
            data: areaData,
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(255, 168, 1,1.0)",
              },
            },
            offset: 20,
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              margin: 0,
              textStyle: {
                color: "#7B4D9F",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
            },
          },
          yAxis: {
            //y轴的样式设置
            show: false,
          },
          series: [
            {
              //柱状图的每个柱的颜色设置
              type: "custom",
              renderItem: (params, api) => {
                const location = api.coord([api.value(0), api.value(1)]);
                return {
                  type: "group",
                  children: [
                    {
                      type: "CubeLeft",
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: new this.$echarts.graphic.LinearGradient(
                          0,
                          0,
                          0,
                          1,
                          [
                            {
                              offset: 0,
                              color: api.style().fill,
                            },
                            {
                              offset: 1,
                              color,
                            },
                          ]
                        ),
                      },
                    },
                    {
                      type: "CubeRight",
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: new this.$echarts.graphic.LinearGradient(
                          0,
                          0,
                          0,
                          1,
                          [
                            {
                              offset: 0,
                              color: api.style().fill,
                            },
                            {
                              offset: 1,
                              color,
                            },
                          ]
                        ),
                      },
                    },
                    {
                      type: "CubeTop",
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: new this.$echarts.graphic.LinearGradient(
                          0,
                          0,
                          0,
                          1,
                          [
                            {
                              offset: 0,
                              color: api.style().fill,
                            },
                            {
                              offset: 1,
                              color: api.style().fill,
                            },
                          ]
                        ),
                      },
                    },
                  ],
                };
              },
              data: value2,
            },
            {
              type: "custom",
              renderItem: (params, api) => {
                const location = api.coord([api.value(0), api.value(1)]);
                return {
                  type: "group",
                  children: [
                    {
                      type: "CubeLeft",
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: new this.$echarts.graphic.LinearGradient(
                          0,
                          0,
                          0,
                          1,
                          [
                            {
                              offset: 0,
                              color: api.style().fill,
                            },
                            {
                              offset: 1,
                              color,
                            },
                          ]
                        ),
                      },
                    },
                    {
                      type: "CubeRight",
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: new this.$echarts.graphic.LinearGradient(
                          0,
                          0,
                          0,
                          1,
                          [
                            {
                              offset: 0,
                              color: api.style().fill,
                            },
                            {
                              offset: 1,
                              color,
                            },
                          ]
                        ),
                      },
                    },
                    {
                      type: "CubeTop",
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: new this.$echarts.graphic.LinearGradient(
                          0,
                          0,
                          0,
                          1,
                          [
                            {
                              offset: 0,
                              color: api.style().fill,
                            },
                            {
                              offset: 1,
                              color: api.style().fill,
                            },
                          ]
                        ),
                      },
                    },
                  ],
                };
              },
              data: value1,
            },
            {
              type: "bar",
              label: {},
              itemStyle: {
                color: "transparent",
              },
              tooltip: {},
              data: value,
            },
          ],
        };

        return buyTopOption;
      };

      if (this.width1 > 7000) {
        // myChart1.setOption(
        //   setbuyTopOption(
        //     "rgba(59, 117, 234,1.0)",
        //     [VALUE[0]],
        //     [VALUE1[0]],
        //     [VALUE2[0]],
        //     "28%"
        //   )
        // );
        // myChart2.setOption(
        //   setbuyTopOption(
        //     "rgba(220, 222, 221,1.0)",
        //     [VALUE[1]],
        //     [VALUE1[1]],
        //     [VALUE2[1]],
        //     "28%"
        //   )
        // );
        myChart3.setOption(
          setbuyTopOption(
            "rgba(166, 244, 196,1.0)",
            [VALUE[2]],
            [VALUE1[2]],
            [VALUE2[2]],
            "28%"
          )
        );
        myChart4.setOption(
          setbuyTopOption(
            "rgba(219, 235, 100,1.0)",
            [VALUE[3]],
            [VALUE1[3]],
            [VALUE2[3]],
            "28%"
          )
        );
        myChart5.setOption(
          setbuyTopOption(
            "rgba(177, 97, 100,1.0)",
            [VALUE[4]],
            [VALUE1[4]],
            [VALUE2[4]],
            "28%"
          )
        );
      } else {
        // myChart1.setOption(
        //   setbuyTopOption(
        //     "rgba(59, 117, 234,1.0)",
        //     [VALUE[0]],
        //     [VALUE1[0]],
        //     [VALUE2[0]],
        //     "7%"
        //   )
        // );
        // myChart2.setOption(
        //   setbuyTopOption(
        //     "rgba(220, 222, 221,1.0)",
        //     [VALUE[1]],
        //     [VALUE1[1]],
        //     [VALUE2[1]],
        //     "7%"
        //   )
        // );
        myChart3.setOption(
          setbuyTopOption(
            "rgba(166, 244, 196,1.0)",
            [VALUE[2]],
            [VALUE1[2]],
            [VALUE2[2]],
            "7%"
          )
        );
        myChart4.setOption(
          setbuyTopOption(
            "rgba(219, 235, 100,1.0)",
            [VALUE[3]],
            [VALUE1[3]],
            [VALUE2[3]],
            "7%"
          )
        );
        myChart5.setOption(
          setbuyTopOption(
            "rgba(177, 97, 100,1.0)",
            [VALUE[4]],
            [VALUE1[4]],
            [VALUE2[4]],
            "7%"
          )
        );
      }
    },
    //左侧监控下拉菜单
    handleCommandLeft(command) {
      command == "b" &&
        window.open(
          "http://49.235.246.2:8088/data/perspective/client/Dapo/camera0001"
        );
    },
    //右侧监控下拉菜单
    handleCommandRight(command) {
      this.$message("click on item " + command);
    },
    to(url) {
      this.$router.push(url);
    },
    // 退出登录
    exitLogin() {
      sessionStorage.clear();
      this.$router.replace("/login");
    },
  },
};
</script>

<style lang="less" scoped>
#index {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;

  .title {
    cursor: default;
  }

  #index_top1 {
    width: 100%;
    position: absolute;
    top: 0px;
    background: url(../assets/index-topBig.png);
    background-repeat: no-repeat;
    background-position: center;
    height: 160px;

    .index_top_title {
      color: white;
      text-align: center;
      font-size: 70px;
      position: absolute;
      top: 35px;
      width: 100%;
    }

    .index_top_time {
      width: 2300px;
      color: white;
      position: absolute;
      top: 21px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 40px;

      .index_top_time_name {
        float: right;
        margin-right: 0px;
      }
    }
  }

  #index_top {
    width: 100%;
    position: absolute;
    top: 0px;
    background: url(../assets/index-top.png);
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;

    .index_top_title {
      color: white;
      text-align: center;
      font-size: 33px;
      position: absolute;
      top: 15px;
      width: 100%;
    }

    .index_top_time {
      width: 1150px;
      color: white;
      position: absolute;
      top: 7px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 20px;

      .index_top_time_name {
        float: right;
        margin-right: 0px;
      }

      .exit_login {
        margin-top: 10px;
        float: right;
        padding: 0 10px;
        font-size: 12px;
        cursor: pointer;
        color: red;

        &:hover {
          color: #409eff;
        }
      }
    }
  }

  .index_sheet_small_top_title {
    font-size: 20px;
    // color: #fff;
    margin: 2px;
    text-align: center;
    font-weight: 700;
    height: 12.77%;
  }

  .index_sheet_small_top_title_small {
    font-size: 20px;
    // color: #fff;
    margin: 2px;
    text-align: center;
    font-weight: 700;
    height: 15.64%;
  }

  .index_sheet_small_top_title_big {
    font-size: 20px;
    padding-top: 6px;
    text-align: center;
    font-weight: 700;
    box-sizing: border-box;
  }

  .index_sheet_small_top_title1 {
    font-size: 35px;
    color: #fff;
    // margin: 10px 10px;
    height: 12.77%;
  }

  #index_sheet_left {
    position: absolute;
    width: 402px;
    height: 91vh;
    top: 70px;
    left: 30px;
    overflow: hidden;
  }

  .index_sheet_big {
    // min-width: 400px;
    height: 23.4vh;
    // background: -webkit-radial-gradient(circle, rgba(0, 0, 0, 0.4), rgba(17, 167, 204, 0.3));
    // border: 1px solid rgb(9, 209, 245);
    // border-radius: 10px;
    margin-bottom: 10px;
    width: 20.4vw;
    max-width: 923.59px;
    // background: url(../assets/222.png);
    // background-repeat: no-repeat;
    // background-size: percentage(100%, 100%);
  }

  #index_sheet_right {
    position: absolute;
    width: 402px;
    height: 91vh;
    top: 70px;
    right: 30px;
    overflow: hidden;
  }

  .personnel {
    width: 100%;
    // min-height: 134px;
    height: calc(19.14vh - 41.4px);
  }

  .personnel1 {
    width: 100%;
    height: 360px;
  }

  .people {
    flex: 1;
    margin-left: 15px;
  }

  .people_red {
    color: #ec7b97;
    margin-bottom: 18px;

    margin-top: 10px;
  }

  .people_green {
    color: #34e08c;
    margin: 18px 0px;
  }

  .people_white {
    color: #fff;
    margin: 0 5px;
  }

  #garbage_volume_sheet {
    width: 100%;
    height: calc(23.4vh - 41.4px);
    overflow: hidden;
  }

  #sewage_monitoring {
    width: 100%;
    // min-height: 180px;
    height: calc(23.4vh - 41.4px);
  }

  .buried_barrel_status100 {
    height: 100%;
    width: 100%;
    // background-color: pink;
    display: flex;
    justify-content: center;
  }

  .buried_barrel_status100_text {
    color: #fff;
    transform: translateY(-40px);
    text-align: left;
    text-indent: 30px;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #fff;
    font-size: calc(100vw * 18 / 1920);
  }

  .el-dropdown-link1 {
    cursor: pointer;
    color: #fff;
    font-size: 35px;
  }

  .el-icon-arrow-down {
    font-size: calc(100vw * 18 / 1920);
  }

  .index_sheet_small {
    // min-width: 400px;
    height: 19.14vh;
    // background: -webkit-radial-gradient(circle, rgba(0, 0, 0, 0.4), rgba(17, 167, 204, 0.3));
    // border: 1px solid rgb(9, 198, 245);
    border-radius: 10px;
    margin-bottom: 10px;
    width: 20.4vw;
    max-width: 923.59px;
    // background: url(../assets/111.png);
    // background-repeat: no-repeat;
    // background-size: percentage(100%, 100%);
  }

  .index_sheet_small_phone {
    background: -webkit-radial-gradient(
      circle,
      rgba(0, 0, 0, 0.4),
      rgba(17, 167, 204, 0.3)
    );
    border: 1px solid rgb(9, 198, 245);
    border-radius: 10px;
    margin-bottom: 10px;
    width: 20%;
    max-width: 923.59px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .index_sheet_left_small_one {
    width: 20.4vw;
    height: 19.1vh;
    border-radius: 10px;
    margin-bottom: 10px;
    position: absolute;
    left: 30px;
    top: 7.44vh;
    max-width: 923.59px;
    background: url(../assets/333.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // min-width: 400px;

    .index_sheet_small_top {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: calc(100% - 46px);

      .small_top {
        // min-width: 115px;
        // min-height: 115px;
        // width: 4vw;
        // height: 4vw;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .small_top_img {
          // min-width: 115px;
          width: 6vw;
          max-width: 295.67px;
          max-height: 295.67px;
          position: absolute;
        }
      }

      .small_top_title {
        height: 114px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        .small_top_title_big {
          font-size: 20px;
          font-weight: 700;
        }

        .small_top_title_small {
          font-size: 12px;
        }
      }
    }
  }

  .index_sheet_big_left_two {
    position: absolute;
    left: 30px;
    top: 27.65vh;
    background: url(../assets/tongwei.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .index_sheet_big_left_three {
    position: absolute;
    left: 30px;
    top: 7.44vh;
    background: url(../assets/dimaitongzhuangtai.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    #buried_barrel_status {
      width: 100%;
      height: calc(19.14vh - 41.4px);
      display: flex;
      max-height: 321.61px;
    }

    .buried_barrel_status99 {
      height: 100%;
      flex: 1;
      text-align: center;
    }
  }

  .index_sheet_big_left_four {
    position: absolute;
    right: 30px;
    top: 32.65vh;
    background: url(../assets/jiankong.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .index_sheet_small_right_one {
    position: absolute;
    right: 30px;
    top: 7.44vh;
    background: url(../assets/cheliang.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .circular {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    .circular_title {
      display: inline-block;
      font-size: 12px;
    }

    .index_sheet_right_top {
      // min-height: 134px;
      display: flex;
      height: calc(19.14vh - 41.4px);

      .index_sheet_right_car {
        // min-height: 134px;
        height: calc(19.14vh - 41.4px);
        flex: 1;

        #index_sheet_right_car_left {
          width: 100%;
          height: 100%;
        }

        #index_sheet_right_car_right {
          width: 100%;
          height: 100%;
        }
      }

      .index_sheet_right_top_title {
        transform: translate(0px, -10vh);
        text-align: center;
        font-size: 19px;
      }
    }
  }

  .index_sheet_small_right_two {
    position: absolute;
    right: 30px;
    top: 27.65vh;
    background: url(../assets/renyuan.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .index_sheet_small_right_three {
    position: absolute;
    right: 30px;
    top: 47.87vh;
    background: url(../assets/shijian.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .index_sheet_small_right_four {
    position: absolute;
    left: 30px;
    top: 54.34vh;

    background: url(../assets/cheliang.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .el-icon-close {
    cursor: pointer;
    z-index: 10000;
    font-size: 20px;
    color: #fff;
  }

  .left_switch {
    width: 30px;
    height: 30px;
    background-color: rgba(9, 198, 245, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .el-icon-caret-right {
      color: white;
      font-size: 30px;
    }
  }

  .left_switch_one {
    position: absolute;
    left: 60px;
    top: 150px;
  }

  .left_switch_two {
    position: absolute;
    left: 60px;
    top: 360px;
  }

  .left_switch_three {
    position: absolute;
    left: 60px;
    top: 570px;
  }

  .left_switch_four {
    position: absolute;
    left: 60px;
    top: 770px;
  }

  .right_switch {
    width: 30px;
    height: 30px;
    background-color: rgba(9, 198, 245, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .el-icon-caret-left {
      color: white;
      font-size: 30px;
    }
  }

  .right_switch_one {
    position: absolute;
    right: 60px;
    top: 150px;
  }

  .right_switch_two {
    position: absolute;
    right: 60px;
    top: 360px;
  }

  .right_switch_three {
    position: absolute;
    right: 60px;
    top: 570px;
  }

  .right_switch_four {
    position: absolute;
    right: 60px;
    top: 770px;
  }

  .small_top_title1 {
    height: 290px !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .small_top_title_big1 {
      font-size: 50px;
      font-weight: 700;
    }

    .small_top_title_small1 {
      font-size: 30px;
    }
  }

  .index_sheet_right_top_title1 {
    transform: translate(0px, -200px) !important;
    text-align: center;
    font-size: 30px !important;
  }

  .videodiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .demo-video {
    width: 300px;
  }

  .demo-video1 {
    width: 750px;
  }

  .rollp {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: white;
    margin: 5px 0;

    span {
      flex: 1;
      text-align: center;
    }
  }

  .rollp1 {
    display: flex;
    justify-content: space-between;
    font-size: 26px;
    color: white;
    margin: 5px 0;

    span {
      flex: 1;
      text-align: center;
    }
  }

  .phoneshow {
    position: fixed;
    top: 80px;
    width: 100vw;
    height: 50px;
    z-index: 99999;
    display: flex;
    justify-content: space-between;
  }
}
</style>
