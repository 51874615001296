<template>
    <div id="test3">
        <div class="test3_big">
            <div class="test3_big_left">
            </div>
            <div class="test3_big_right">
                <div class="test3_monitor">
                    <h1>多屏监控</h1>
                </div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
                <div class="test3_monitor"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DemoTest3',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
#test3 {
    height: 100vh;
    width: 100vw;
}

.test3_big {
    width: 100%;
    height: 100%;
    display: flex;
}

.test3_big_left {
    width: 300px;
    height: 100%;
    background-color: #192533;
}

.test3_big_right {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
}

.test3_monitor {
    border: 1px solid #000;
    width: calc(25% - 2px);
    height: calc(25% - 2px);
}
</style>
