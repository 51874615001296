<template>
  <div class="player_wrapper">
    <div id="video_player"></div>
  </div>
</template>

<script>
// import EZUIKit from "ezuikit-js";
export default {
  name: "EZUIKit",
  data: () => {
    return {
      _Player: null,
    };
  },
  props: {
    url: {
      type: String,
      default() {
        return "";
      },
    },
    atoken: {
      type: String,
      default() {
        return "";
      },
    },
  },
  watch: {
    url(newV) {
      console.log("newVnewVnewVnewV", newV);
    },
  },
  methods: {
    iniPlayer() {
      this._Player = new EZUIKit.EZUIKitPlayer({
        id: "video_player",
        accessToken: this.$props.atoken,
        url: this.$props.url,
        height: 500,
        width: 700,
        template: "pcLive",
      });

      console.log("this._Player", this._Player);

      // this._Player.play()
    },
  },
  mounted() {
    this.iniPlayer();
    // this._Player.browserFullscreen();
    // console.log("1111111111111111111111111111111111111", this.$props);
  },
  beforeDestroy() {
    // console.log("卸载七千--------------", this._Player);

    this._Player.destroy();
  },
};
</script>

<style lang="less" scoped>
.player_wrapper {
  width: 100%;
  height: 100%;
}
</style>
