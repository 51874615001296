<template>
  <div>
    <div class="crumbs">设置</div>
    <div class="center">
      <div class="table">
        <div class="center_top">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="筛选">
              <el-select v-model="type" placeholder="请选择">
                <el-option :label="'全部'" :value="''"> </el-option>
                <el-option :label="'业务经理'" :value="1"> </el-option>
                <el-option :label="'项目经理'" :value="2"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="getList"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="medium"
                @click="editAdminShowChange(true)"
                >添加管理员</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="medium"
                @click="dialogVisible = true"
                >修改密码</el-button
              >
            </el-form-item>

            <el-form-item>
              <el-button type="primary" size="medium" @click="roleListdatafn"
                >编辑角色组权限</el-button
              >
            </el-form-item>

            <el-form-item>
              <el-button type="primary" size="medium" @click="goToUrl"
                >街道管理</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }"
        >
          <el-table-column
            prop="user_id"
            label="编号"
            :align="'center'"
            width="50"
          >
          </el-table-column>
          <el-table-column
            prop="user_phone"
            label="管理员手机号"
            :align="'center'"
          >
          </el-table-column>
          <el-table-column prop="user_name" label="用户名" :align="'center'">
          </el-table-column>
          <el-table-column label="用户角色" :align="'center'">
            <template slot-scope="scope">
              {{ usertype[scope.row.user_type] }}
            </template>
          </el-table-column>
          <el-table-column prop="c_time" label="创建时间" :align="'center'">
          </el-table-column>
          <el-table-column label="用户状态" :align="'center'">
            <template slot-scope="scope">
              {{ status[scope.row.status] }}
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="editAdminShowChange(true, scope.row)"
                >编辑管理员</el-button
              >
              <!-- <el-button type="text" @click="AssignStreet(scope.row.user_id)">分配街道</el-button> -->
              <el-button
                type="text"
                style="color: red"
                @click="delAdminName(scope.row.user_id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div>
        <span style="float: right; margin-top: 20px; margin-right: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-sizes="[1000]"
            :page-size="1000"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.tableData.length"
            background
          >
          </el-pagination>
        </span>
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="旧密码" prop="old_password">
            <el-input
              v-model="ruleForm.old_password"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="new_password">
            <el-input
              v-model="ruleForm.new_password"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="new_password1">
            <el-input
              v-model="ruleForm.new_password1"
              type="password"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changePassword">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="isEditAdmin ? '编辑管理员' : '添加管理员'"
      :visible.sync="editAdminShow"
      width="30%"
    >
      <div>
        <el-form
          :model="addfromdata"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户名" prop="">
            <el-input v-model="addfromdata.user_name"></el-input>
          </el-form-item>
          <el-form-item label="登陆账号" prop="">
            <el-input v-model="addfromdata.user_phone"></el-input>
          </el-form-item>
          <el-form-item label="用户角色" prop="">
            <el-select v-model="addfromdata.user_type" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="省" prop="">
            <el-select
              v-model="addfromdata.provinceid"
              placeholder="请选择省份"
              @change="provinceidChange"
            >
              <el-option
                v-for="item in ProvinceList"
                :key="item.provinceid"
                :label="item.province"
                :value="item.provinceid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市" prop="">
            <el-select
              v-model="addfromdata.cityid"
              placeholder="请先选择省份再选择市"
              @change="cityidChange"
            >
              <el-option
                v-for="item in CityList"
                :key="item.cityid"
                :label="item.city"
                :value="item.cityid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="区" prop="">
            <el-select
              v-model="addfromdata.areaid"
              placeholder="请先选择市再选择区"
              @change="areaidChange"
            >
              <el-option
                v-for="item in DistrictList"
                :key="item.areaid"
                :label="item.area"
                :value="item.areaid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="街道" prop="">
            <el-select
              v-model="addfromdata.street_id"
              placeholder="请先选择区再选择街道"
            >
              <el-option
                v-for="item in StreetList"
                :key="item.street_id"
                :label="item.street_name"
                :value="item.street_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户登录密码" prop="">
            <el-input v-model="addfromdata.password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editAdminShowChange(false)">取 消</el-button>
        <el-button type="primary" @click="addAdminfn">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="角色组权限设置"
      :visible.sync="Editpermissions"
      width="30%"
      :before-close="beforeclose"
    >
      <div v-if="!editTorF">
        <el-collapse>
          <el-collapse-item
            :title="item.name"
            :name="item.id"
            v-for="item in roleListdata"
            :key="item.id"
          >
            <span>已有权限: &nbsp;</span
            ><span>{{ item.id == 3 ? "所有权限" : item.title }}</span>
            <p>
              <el-button
                v-if="item.id < 3"
                type="text"
                @click="Editpermissionsfn(item.id)"
                >编辑权限</el-button
              >
            </p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div v-if="editTorF">
        <el-collapse>
          <el-collapse-item
            :title="item.remark"
            :name="item.id"
            v-for="(item, index) in roleRuleEditPagelist"
            :key="item.id"
          >
            <el-checkbox-group v-model="child1[index]">
              <el-checkbox
                :label="item1.id"
                v-for="item1 in item.child"
                :key="item1.id"
                :value="item1.is_checked"
                >{{ item1.remark }}</el-checkbox
              >
            </el-checkbox-group>
          </el-collapse-item>
        </el-collapse>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="beforeclose">取 消</el-button>
        <el-button type="primary" @click="rulesInfoPostfn">确 定</el-button>
      </span>
    </el-dialog>

    <!-- <el-dialog title="分配街道" :visible.sync="AssignStreetTorF" width="30%">
            <div>
                <el-select v-model="street_id" placeholder="请选择">
                    <el-option v-for="item in StreetList" :key="item.street_id" :label="item.street_name"
                        :value="item.street_id">
                    </el-option>
                </el-select>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="AssignStreetTorF = false">取 消</el-button>
                <el-button type="primary" @click="setAssignStreet">确 定</el-button>
            </span>
        </el-dialog> -->

    <el-dialog title="添加街道" :visible.sync="addStreetShow" width="30%">
      <el-form
        :model="addfromdata"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="省" prop="">
          <el-select
            v-model="provinceid"
            placeholder="请选择省份"
            @change="provinceidChange"
          >
            <el-option
              v-for="item in ProvinceList"
              :key="item.provinceid"
              :label="item.province"
              :value="item.provinceid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市" prop="">
          <el-select
            v-model="cityid"
            placeholder="请先选择省份再选择市"
            @change="cityidChange"
          >
            <el-option
              v-for="item in CityList"
              :key="item.cityid"
              :label="item.city"
              :value="item.cityid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区" prop="">
          <el-select v-model="areaid" placeholder="请先选择市再选择区">
            <el-option
              v-for="item in DistrictList"
              :key="item.areaid"
              :label="item.area"
              :value="item.areaid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="街道" prop="">
          <el-input
            v-model="street_name"
            placeholder="请输入街道名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addStreetShow = false">取 消</el-button>
        <el-button type="primary" @click="addStreetFn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  ClientUserList,
  revisePassword,
  addAdmin,
  adminUserDelete,
  editAdmin,
  roleList,
  roleRuleEditPage,
  rulesInfoPost,
  streetDistributeToAdmin,
  GetStreetList,
  addStreet,
  getProvince,
  getCity,
  getDistrict,
} from "../request/api";
export default {
  name: "DemoShezhi",

  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.new_password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      addStreetShow: false,
      editAdminShow: false,
      tableData: [],
      usertype: ["", "业务经理", "项目经理", "超级管理员"],
      status: ["停用", "正常"],
      dialogVisible: false,
      ruleForm: {
        old_password: "",
        new_password: "",
        new_password1: "",
      },
      rules: {
        old_password: {
          required: true,
          message: "请输入旧密码",
          trigger: "blur",
        },
        new_password: [
          { validator: validatePass, trigger: "blur" },
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        new_password1: [
          { validator: validatePass2, trigger: "blur" },
          { required: true, message: "请再次输入新密码", trigger: "blur" },
        ],
      },
      editAdminShow: false,
      isEditAdmin: false,
      addfromdata: {
        user_id: "",
        user_name: "",
        user_phone: "",
        user_type: null,
        password: "",
        provinceid: "", // 省
        cityid: "", // 市
        areaid: "", // 区
        street_id: "", // 街
        manager_area_id: "",
        grant_level: "",
      },
      options: [
        {
          value: 2,
          label: "项目经理",
        },
        {
          value: 1,
          label: "业务经理",
        },
      ],
      Editpermissions: false,
      roleRuleEditPagelist: [],
      child1: [[], [], [], [], [], []],
      roleListdata: [],
      editTorF: false,
      AssignStreetTorF: false,
      id: null,

      type: "",
      // 省
      ProvinceList: [],
      provinceid: null,
      provincename: "",
      // 区
      DistrictList: [],
      areaid: null,
      areaname: "",
      // 市
      CityList: [],
      cityid: null,
      cityname: "",
      // 街道
      StreetList: [],
      street_id: null,
      street_name: "",
    };
  },

  async mounted() {
    this.getList();
    this.getProvinceFn();
  },

  methods: {
    async addStreetFn() {
      let res = await addStreet({
        province_id: this.provinceid,
        city_id: this.cityid,
        district_id: this.areaid,
        street_name: this.street_name,
      });
      if (res.code == 1) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.addStreetShow = false;
        this.provinceid = null;
        this.cityid = null;
        this.areaid = null;
        this.street_name = "";
      } else {
        this.$message.error("参数错误，请检查是否有遗漏再进行提交");
      }
    },
    async getProvinceFn() {
      let res = await getProvince();
      this.ProvinceList = res.data;
    },
    async provinceidChange(provinceid) {
      let res = await getCity({
        province_id: provinceid,
      });

      this.CityList = res.data;

      this.addfromdata.cityid = null;
      this.addfromdata.areaid = null;
      this.addfromdata.street_id = null;

      this.cityid = null;
      this.areaid = null;
      this.street_id = null;
    },
    async cityidChange(cityid) {
      let res = await getDistrict({
        city_id: cityid,
      });
      this.DistrictList = res.data;

      this.addfromdata.areaid = null;
      this.addfromdata.street_id = null;

      this.areaid = null;
      this.street_id = null;
    },
    async areaidChange(district_id) {
      const res = await GetStreetList({
        district_id,
      });
      this.StreetList = res.data.list;
      this.addfromdata.street_id = null;
      this.street_id = null;
    },

    goToUrl() {
      this.$router.replace({
        path: "/streetList",
      });
    },

    handleSizeChange(val) {},
    handleCurrentChange(val) {},
    //修改密码
    changePassword() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          let res = await revisePassword({
            old_password: this.ruleForm.old_password,
            new_password: this.ruleForm.new_password,
          });

          console.log(res);
        } else {
          return false;
        }
      });
    },
    async addAdminfn() {
      const useAxiosfuc = this.isEditAdmin ? editAdmin : addAdmin;

      let params = {
        user_phone: this.addfromdata.user_phone,
        user_name: this.addfromdata.user_name,
        user_type: this.addfromdata.user_type,
        grant_level: this.addfromdata.grant_level,
        manager_area_id: this.addfromdata.manager_area_id,
      };

      if (!isNaN(this.addfromdata.provinceid)) {
        let _grant_level = 1;
        let _list_ = null;
        let flag = "";
        if (this.addfromdata.street_id) {
          _grant_level = 4;
          _list_ = this.StreetList;
          flag = "street_id";
        } else if (this.addfromdata.areaid) {
          _grant_level = 3;
          _list_ = this.DistrictList;
          flag = "areaid";
        } else if (this.addfromdata.cityid) {
          _grant_level = 2;
          _list_ = this.CityList;
          flag = "cityid";
        } else if (this.addfromdata.provinceid) {
          _grant_level = 1;
          _list_ = this.ProvinceList;
          flag = "provinceid";
        } else {
          this.$message({
            message: "请选择区域",
            type: "error",
          });
          return;
        }

        console.log("this.addfromdata.provinceid", this.addfromdata.provinceid);

        const _manager_area_id = _list_.find((item) => {
          return item[flag] == this.addfromdata[flag];
        });

        const _id =
          flag == "street_id" ? _manager_area_id[flag] : _manager_area_id.id;

        params.manager_area_id = _id;
        params.grant_level = _grant_level;

        console.log("paramsparamsparams", params);
      }

      if (this.isEditAdmin) {
        params = {
          ...params,
          user_id: this.addfromdata.user_id,
        };
      }

      if (this.addfromdata.password != "") {
        const base1 = this.encode(this.addfromdata.password);
        let basearr = base1.split("");
        let basearr0 = basearr[0];
        basearr[0] = basearr[2];
        basearr[2] = basearr0;
        const base2 = this.encode(basearr.join(""));

        params = {
          ...params,
          password: base2,
        };
      } else if (!this.isEditAdmin && this.addfromdata.password == "") {
        this.$message({
          message: "密码不可为空",
          type: "error",
        });

        return;
      }

      console.log("this.isEditAdmin", this.isEditAdmin);

      console.log("params", params);

      const res = await useAxiosfuc(params);

      if (res.code === 1) {
        this.editAdminShowChange(false);
        this.$message({
          message: "添加成功",
          type: "success",
        });
        this.addfromdata = {
          user_name: "",
          user_phone: "",
          user_type: null,
          password: "",
          street_id: "",
          user_id: "",
        };

        let res1 = await ClientUserList();
        this.tableData = res1.data.list;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 编辑管理员
    editAdminShowChange(type, data = { user_id: "" }) {
      this.editAdminShow = type;
      console.log(this.ProvinceList);
      console.log("-------------data----------------", data);
      if (data.user_id != "") {
        this.isEditAdmin = true;
        const { user_name, user_phone, user_type, user_id } = data;
        this.addfromdata.user_name = user_name;
        this.addfromdata.user_phone = user_phone;
        this.addfromdata.user_type = user_type;
        this.addfromdata.user_id = user_id;
        this.addfromdata.provinceid = data.province_name;
        this.addfromdata.cityid = data.city_name;
        this.addfromdata.areaid = data.area_name;
        this.addfromdata.street_id = data.street_name;
        this.addfromdata.grant_level = data.grant_level;
        this.addfromdata.manager_area_id = data.manager_area_id;

        // this.addfromdata.password = atob(data.password)
      } else if (this.isEditAdmin) {
        this.addfromdata.user_name = "";
        this.addfromdata.user_phone = "";
        this.addfromdata.user_id = "";
        this.addfromdata.user_type = null;
        this.isEditAdmin = false;
        this.addfromdata.provinceid = null;
        this.addfromdata.cityid = null;
        this.addfromdata.areaid = null;
        this.addfromdata.street_id = null;
        this.addfromdata.grant_level = "";
        this.addfromdata.manager_area_id = "";
      }
    },
    // 删除
    async delAdminName(user_id) {
      this.$confirm("此操作将永久删除该管理员, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await adminUserDelete({ user_id });
          console.log(res);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          // this.$message({
          //     type: 'info',
          //     message: '已取消删除'
          // });
        });
    },

    //base64加密
    encode(str) {
      if (str === undefined || str === "" || str === null) {
        return str;
      }
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }
        )
      );
    },
    //点击编辑角色组权限按钮
    async roleListdatafn() {
      this.Editpermissions = true;
      let res = await roleList();
      this.roleListdata = res.data.list;
    },
    //角色规则编辑提交
    async rulesInfoPostfn() {
      let str = this.child1.flat(Infinity);

      let res = await rulesInfoPost({
        role_id: 2,
        rules: str.join(","),
      });
      if (res.code === 1) {
        this.editTorF = false;
        this.Editpermissions = false;
        this.$message({
          message: "修改成功",
          type: "success",
        });
      }
    },
    async Editpermissionsfn(id) {
      this.editTorF = true;
      let res1 = await roleRuleEditPage({ role_id: id });
      this.roleRuleEditPagelist = res1.data.list;
      this.roleRuleEditPagelist.forEach((item, index) => {
        item.child.forEach((it, ind) => {
          if (it.is_checked === 1) {
            this.child1[index].push(it.id);
          }
        });
      });
    },
    beforeclose() {
      this.editTorF = false;
      this.Editpermissions = false;
    },
    //分配街道
    async AssignStreet(id) {
      this.id = id;
      this.AssignStreetTorF = true;
      // let res = await GetStreetList()
      // this.StreetList = res.data.list
    },
    async setAssignStreet() {
      let res = await streetDistributeToAdmin({
        street_id: this.street_id,
        admin_id: this.id,
      });
      console.log(res);
    },
    async getList() {
      let res = await ClientUserList({
        type: this.type,
      });

      this.tableData = res.data.list;
    },
  },
};
</script>

<style lang="less" scoped>
.crumbs {
  padding: 5px 20px;
  background-color: rgb(240, 240, 240);
}

.center {
  width: 100vw;
  height: calc(100% - 31px);
  margin: auto;
  overflow: auto;
  box-sizing: border-box;
}

.abc {
  display: flex;
  flex-wrap: wrap;
  margin-left: 25px;
}

::v-deep .el-radio {
  margin: 5px 0;
  margin-right: 20px;
}

.table {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 0 10px;
}

.center_top {
  padding-top: 10px;
  padding-bottom: 13px;
  height: 36px;
  display: flex;
  justify-content: center;

  ::v-deep .el-input__inner {
    width: 190px;
    height: 32px;
  }
}
</style>
